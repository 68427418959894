export let browserWindow = {};

if (window && window.Cms) {
  browserWindow = window;
} else {
  browserWindow = {
    Cms: {
      Environment: 'local',
      CurrentSiteNodeId: 1056,
      CurrentLanguageRegion: 'en-us'
    }
  };
  if (window.location.pathname == '/search.html') {
    browserWindow.Cms.searchPage = true;
  }
}
export const env = browserWindow.Cms.Environment != null ? browserWindow.Cms.Environment : 'local';

if (env === 'local') {
  browserWindow.Cms.CurrentSiteNodeId = 1056;
  if (window.location.pathname == '/search.html') {
    browserWindow.Cms.searchPage = true;
  }
  browserWindow.Cms.CurrentLanguageRegion = 'en-us';
}

export const searchName = browserWindow.Cms.searchSettings
  ? window.Cms.searchSettings.searchResultContent.searchName
  : 'vacation-search';
export const LOCATION_STRING = env === 'local' ? 'http://local.travel-ttc.com' : '';
export const REGION_URL = '/' + browserWindow.Cms.CurrentLanguageRegion;
export const SEARCH_SERVICE_URL = LOCATION_STRING + REGION_URL + '/' + searchName + '/search?';
export const AUTO_COMPLETE_PATH = REGION_URL + '/autocomplete?term=';
export const SEARCH_SERVICE_TIMEOUT = 30000;
export const MIN_PRICE_SEARCH_PREFIX = 'minprice=';
export const MAX_PRICE_SEARCH_PREFIX = 'maxprice=';
export const MINDURATIONSEARCHPREFIX = 'minduration=';
export const MAXDURATIONSEARCHPREFIX = 'maxduration=';
export const MONTHSSELECTEDPREFIX = 'months=';
export const BRANDSSELECTEDPREFIX = 'brands=';
export const TYPESSELECTEDPREFIX = 'travelTypes=';
export const DEFAULTSEARCHTERM = 'destinations=';
export const REGIONSSEARCHTERM = 'continents=';
export const TAGSSEARCHTERM = 'tags=';
export const FREETEXTSEARCHTERM = 'text=';
export const BROCHURECODESSEARCHTERM = 'brochurecodes=';
export const TOURSEARCHTERM = 'tour=';
export const PAGETERM = '&page=';
export const CURRENCY = '$';
export const FILTERTEXTMONTHS = ' Months';
export const FILTERTEXTDAYS = ' Days';
export const FILTERTEXTBRANDS = ' Vacation Brands';
export const FILTERTEXTTYPES = ' Travel Types';
export const FILTERTEXTDESTINATIONS = ' Countries';
export const SEARCHERRORMESSAGE =
  'There was an error loading the Search Results. Please try later.';
export const COUNTRIESDISPLAYLIMIT = 5;
export const LOCATIONSDISPLAYCHARACTERLIMIT = 60;
export const PRICESLIDERMINVAL = 0;
export const PRICESLIDERMAXVAL = 20000;
export const DURATIONSLIDERMINVAL = 0;
export const DURATIONSLIDERMAXVAL = 40;
export const TRIPDISPLAYLIMIT = 5;
export const DAYSMINVAL = 0;
export const DAYSMAXVAL = 40;
