// constants required as they are referenced
import * as searchConstants from '../../search-constants.js';

function setRegionSearch(regionValue) {
  if (regionValue === false) {
    this.resetDestinationFilters();
  } else {
    let regionValueToDisplay = null;
    this.$data.settings.currentSearchPrefix = searchConstants.REGIONSSEARCHTERM;
    this.$data.settings.currentDestinations = false;
    this.$data.settings.currentContinent = regionValue;

    regionValueToDisplay = this.matchApiDestinations(regionValue, 'continent');
    this.$data.settings.filtersText.destinationsText = regionValueToDisplay;
  }
}
module.exports = setRegionSearch;
