function mobileMenu() {
  const getCurrentScreenSize = require('../ui-functions/get-current-screen-size');
  let nav = document.querySelector('#navbarMenu');
  let navParent = document.querySelector('.navbar');

  if (!navParent) {
    return;
  }
  let navActiveClass = 'is-active';
  let mobileActiveClass = 'is-mobile-nav-active';
  let searchActiveClass = 'is-mobile-search-active';
  let btnLastFired = null;
  let mobileNavBtn = document.querySelector('.navbar-burger');
  let mobileSearchBtn = document.querySelector('.navbar-mobile-search-btn');
  let navParents = document.querySelectorAll('.navbar-item.has-dropdown');
  let bodyEl = document.querySelector('body');
  let navActiveBodyClass = 'is-nav-active';

  mobileNavBtn.addEventListener('click', (event) => {
    if (btnLastFired === event.currentTarget) {
      nav.classList.toggle(navActiveClass);
      bodyEl.classList.toggle(navActiveBodyClass);
    } else {
      nav.classList.add(navActiveClass);
      bodyEl.classList.add(navActiveBodyClass);
    }

    navParent.classList.toggle(mobileActiveClass);
    navParent.classList.remove(searchActiveClass);
    btnLastFired = event.currentTarget;
  });

  mobileSearchBtn.addEventListener('click', () => {
    if (btnLastFired === event.currentTarget) {
      nav.classList.toggle(navActiveClass);
    } else {
      nav.classList.add(navActiveClass);
    }

    navParent.classList.toggle(searchActiveClass);
    navParent.classList.remove(mobileActiveClass);
    bodyEl.classList.remove(navActiveBodyClass);

    btnLastFired = event.currentTarget;
  });

  let mobileBackBtns = document.querySelectorAll('.navbar-mobile-go-back');

  for (let i = 0; i < mobileBackBtns.length; i++) {
    let currBtn = mobileBackBtns[i];

    currBtn.addEventListener('click', () => {
      currBtn.parentNode.parentNode.classList.remove(navActiveClass);
    });
  }

  function resetNavs() {
    nav.classList.remove(navActiveClass);
    navParent.classList.remove(searchActiveClass);
    navParent.classList.remove(mobileActiveClass);
    bodyEl.classList.remove(navActiveBodyClass);

    for (let i = 0; i < navParents.length; i++) {
      let currBtn = navParents[i];
      currBtn.classList.remove(navActiveClass);
    }
  }

  window.addEventListener('resize', (event) => {
    let currSs = getCurrentScreenSize();

    if (currSs === 'ls' || currSs === 'xls') {
      resetNavs();
    }
  });
}
module.exports = mobileMenu;
