const $ = require('jquery');
function stickyMap() {
  const itinerarySection = $('#itinerary-section');
  const includedSection = $('#included-section');
  const itinerarySectionPresent = itinerarySection.length;
  let mapholder = $('.map-holder');

  let itinerarySectionHeight;
  let mapholderHeight = mapholder.height();

  if (itinerarySectionPresent) {
    let windowWidth;
    let fixedMapClass = 'sticky-map-fixed';
    let absoluteMapClass = 'sticky-map-absolute';

    function stickyMapInit() {
      windowWidth = $(window).outerWidth();

      if (windowWidth > 768) {
        let mapContainer = $('.column.map');
        let mapContainerWidth = mapContainer.width();
        let mapholderHeight = mapholder.height();
        let itineraryOffsetTop;
        let scrollTop;
        let itinerarySectionBottomOffset;
        let isFixed = false;

        itineraryOffsetTop = itinerarySection.offset().top - 64;
        itinerarySectionBottomOffset =
          itinerarySection.offset().top + itinerarySection.outerHeight() - (mapholderHeight + 130);
        scrollTop = $(window).scrollTop();

        //function to remove the fixed/absolute class from '.map-holder' to stop the sticky nav overlapping the modal background
        function toggleMapHolderClass(elementClass1, elementClass2) {
          $('.expand').click(function () {
            if ($('.map-holder').hasClass(fixedMapClass)) {
              isFixed = true;
            } else {
              isFixed = false;
            }
            mapholder.removeClass(elementClass1 + ' ' + elementClass2);
          });

          itinerarySection.find('.modal-card-close').click(function () {
            if (isFixed) {
              mapholder.addClass(elementClass1);
            }
            mapholder.removeClass(elementClass2);
          });
        }
        //scroll top is between the top and bottom offsets of the Itinerary Section
        if (scrollTop > itineraryOffsetTop && scrollTop < itinerarySectionBottomOffset) {
          mapholder.removeClass(absoluteMapClass);
          mapholder.addClass(fixedMapClass);
          mapholder.css({
            width: mapContainerWidth
          });

          toggleMapHolderClass(fixedMapClass, absoluteMapClass);
          //scroll top is above the offset of the Itinerary Section
        } else if (scrollTop < itineraryOffsetTop) {
          mapholder.removeClass(fixedMapClass);
          //scroll top is below the offset of the Itinerary Section
        } else if (scrollTop > itinerarySectionBottomOffset) {
          mapholder.removeClass(fixedMapClass);
          mapholder.addClass(absoluteMapClass);
          toggleMapHolderClass(absoluteMapClass, fixedMapClass);
        }
      }
    }

    $(window).scroll(function () {
      itinerarySectionHeight = itinerarySection.height();

      if (itinerarySectionHeight > mapholderHeight) {
        stickyMapInit();
      }
    });

    $(window).resize(function () {
      stickyMapInit();
      windowWidth = $(window).outerWidth();
      //removing all classes prefixed with 'sticky-map' to reset the styles for mobile devices
      if (windowWidth <= 768) {
        mapholder.removeClass('sticky-map-fixed sticky-map-absolute');
        mapholder.css({ width: '' });
      }
    });
  }
}
module.exports = stickyMap;
