function initializeDestinationMobileMenu() {
  let selectDestination = document.querySelectorAll('.destination-wrapper')[0];

  selectDestination.addEventListener('click', function () {
    this.querySelector('.destination-mobile').classList.toggle('open');
  });

  window.addEventListener('click', function (e) {
    const select = selectDestination;
    if (!select.contains(e.target)) {
      select.classList.remove('open');
    }
  });

  for (const dropdown of document.querySelectorAll('.destination-wrapper')) {
    dropdown.addEventListener('click', function () {
      this.querySelector('.destination-mobile').classList.toggle('open');
    });
  }
  window.addEventListener('click', function (e) {
    for (const select of document.querySelectorAll('.destination-mobile')) {
      if (!select.contains(e.target)) {
        select.classList.remove('open');
      }
    }
  });
}

module.exports = initializeDestinationMobileMenu;
