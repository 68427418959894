import Vue from 'vue';
import ContentModules from '../vue-components/content-modules/content-modules.vue';

function contentModules() {
  new Vue({
    el: '#content-modules',
    components: {
      'content-modules': ContentModules
    },
    data() {
      return {
        modulesModel: window.Cms.modules,
        loginModel: window.Cms.loginCall
      };
    }
  });
}
module.exports = contentModules;
