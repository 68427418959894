var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"featured feature-section"},_vm._l((_vm.featuredsection),function(feature){return _c('div',{staticClass:"feature-section__item",style:([
      _vm.$mq == 'desktop'
        ? {
            backgroundImage: 'url(\'' + feature.backgroundImageUrl + '\')'
          }
        : ''
    ]),attrs:{"id":feature.featureId,"arial-label":feature.backgroundImageAltText}},[(_vm.$mq === 'laptop' || _vm.$mq === 'tablet' || _vm.$mq === 'mobile')?_c('div',{staticClass:"feature-section__item-bg"},[(feature.backgroundImageUrl)?_c('img',{staticClass:"item-bg",attrs:{"src":feature.backgroundImageUrl,"alt":feature.backgroundImageAltText}}):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"feature-section__content",class:feature.featureType === 'brand' &&
        (feature.featureId.toLowerCase() === 'luxury-gold' ||
          feature.featureId.toLowerCase() === 'trafalgar')
          ? 'bg-' + feature.featureId.toLowerCase()
          : 'bg-feature'},[_c('div',{staticClass:"feature-section__content-inner"},[_c('div',{staticClass:"feature-smallImage"},[(feature.imageUrl && feature.featureType !== 'destination')?_c('img',{staticClass:"feature-image",class:feature.featureType === 'brand' ? 'feature-logo-image' : '',attrs:{"src":feature.imageUrl,"alt":feature.imageAltText}}):_vm._e(),_vm._v(" "),(
              feature.featureType === 'destination' ||
              feature.featureType === 'homeTravelStyleFeature'
            )?_c('h3',{staticClass:"feature-title"},[_vm._v("\n            "+_vm._s(feature.featureTitleName)+"\n          ")]):_vm._e()]),_vm._v(" "),(feature.descriptionText)?_c('div',{staticClass:"feature-text",domProps:{"innerHTML":_vm._s(feature.descriptionText)}}):_vm._e(),_vm._v(" "),(feature.linkUrl)?_c('footer',{staticClass:"feature-section__footer"},[_c('div',{staticClass:"feature-section__footer-item",class:feature.featureType !== 'homeTravelStyleFeature' ? 'center' : ''},[_c('a',{staticClass:"button is-primary cta",attrs:{"href":feature.linkUrl,"aria-label":feature.linkName,"target":"_blank"}},[_vm._v(_vm._s(feature.linkName))])])]):_vm._e()])])])}),0)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }