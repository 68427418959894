import Vue from 'vue';
import ShipsView from '../vue-components/ships/ships.vue';

function shipsView() {
  new Vue({
    el: '#ships-section',
    components: {
      'ships-view': ShipsView
    },
    data() {
      return {
        shipsModel: window.Cms.shipsModel
      };
    }
  });
}
module.exports = shipsView;
