import axios from 'axios';
import * as searchConstants from '../../search-constants.js';

function fetchSearch(filter, mergeExistingResults = false, callDeepLinkFunction = false) {
  let baseSearchUrl = searchConstants.SEARCH_SERVICE_URL;
  let searchService = baseSearchUrl;
  let apiCall = searchService + filter + searchConstants.PAGETERM + this.$data.currentPage;

  if (this.$data.viewLimit !== this.$data.defaultViewLimit) {
    apiCall += '&PageSize=' + this.$data.viewLimit;
  }

  let productsArray = [];
  let productString = '';

  this.$data.settings.error = false;
  this.$data.settings.loading = true;
  this.$data.settings.totalResults = 0;

  axios
    .get(apiCall, { timeout: searchConstants.SEARCH_SERVICE_TIMEOUT })
    .then((response) => {
      productString = response.data;
      productsArray.push(productString);
      this.$data.products = productsArray;
      this.$data.settings.totalResults = this.$data.products[0].totalHits;
      let tourResults = this.$data.products[0].results;
      this.$data.currencySymbol = this.$data.products[0].currencySymbol;

      // if there are already tour results, and the mergeExistingResultsParam is passed as true from show more
      if (this.$data.tourResults.length > 0 && mergeExistingResults) {
        let combinedTours = this.$data.tourResults.concat(tourResults);
        this.$data.tourResults = combinedTours;
        this.$data.settings.resultsInView += this.$data.viewLimit;
        this.$data.settings.showMoreLoading = false;
      } else {
        this.$data.tourResults = this.$data.products[0].results;
        this.resetSearch();
        this.$data.settings.resultsInView += this.$data.viewLimit;
      }

      if (callDeepLinkFunction) {
        this.manageDeepLinkState();
      }

      this.$data.settings.loading = false;
      this.sendGoogleAnalyticsSearchCriteria(apiCall);

      setTimeout(() => {
        this.checkSearchResultHeight();
      }, 50);
    })
    .catch(() => {
      this.$data.settings.error = true;
      this.$data.settings.loadingMessage = searchConstants.SEARCHERRORMESSAGE;
      this.$data.settings.loading = false;
    });
}
module.exports = fetchSearch;
