function materializeFunctions() {
  const tabs = $('.tabs');
  if (tabs) {
    tabs.tabs();
  }
  const heroCarousel = $('.hero-carousel');
  if (heroCarousel) {
    heroCarousel.carousel({
      fullWidth: true,
      indicators: true,
      duration: 0
    });
    $('.prev').click(function () {
      heroCarousel.carousel('prev');
    });
    $('.next').click(function () {
      heroCarousel.carousel('next');
    });
    heroCarousel.css({
      height: 'auto'
    });
  }
  const shipCarousel = $('.ship-carousel');
  const shipCarouselItem = $('.carousel-item').length;
  if (shipCarouselItem) {
    shipCarousel.each(function () {
      const $self = $(this);
      $(this).carousel({
        fullWidth: true,
        indicators: true,
        duration: 0
      });
      $(this)
        .siblings('.prev')
        .click(function () {
          $self.carousel('prev');
        });
      $(this)
        .siblings('.next')
        .click(function () {
          $self.carousel('next');
        });
    });
  }
}
module.exports = materializeFunctions;
