import Vue from 'vue';
import FeatureIntroSection from '../vue-components/featured/featured-intro-section.vue';

function featureIntroSectionView() {
  new Vue({
    el: '#featured-intro-section',
    components: {
      'featured-intro-section': FeatureIntroSection
    },
    data() {
      return {
        featuredsection: window.Cms.featuredSection
      };
    }
  });
}
module.exports = featureIntroSectionView;
