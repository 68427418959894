import Vue from 'vue';
import FeatureBrands from '../vue-components/featured/featured-brands.vue';

function featureBrands() {
  new Vue({
    el: '#featured-brands',
    components: {
      'featured-brands': FeatureBrands
    },
    data() {
      return {
        brandsdata: window.Cms.brandsModel
      };
    }
  });
}
module.exports = featureBrands;
