import Vue from 'vue';
import VeeValidate from 'vee-validate';
import PortalVue from 'portal-vue';

Vue.use(VeeValidate, {
  events: 'change'
});

Vue.use(PortalVue);
import QuoteFtcHeaderButton from '../vue-components/modals/ftc-modal-button-header.vue';
import QuoteFtcFooterButton from '../vue-components/modals/ftc-modal-button-footer.vue';
import QuoteFtcModal from '../vue-components/modals/ftc-modal.vue';

function websiteFtcModals() {
  new Vue({
    el: '#app-ftcModals',
    components: {
      'quote-ftc-header-button': QuoteFtcHeaderButton,
      'quote-ftc-footer-button': QuoteFtcFooterButton,
      'quote-ftc-modal': QuoteFtcModal
    },
    data: function () {
      return window.Cms.ftcmodals;
    }
  });
}
module.exports = websiteFtcModals;
