import Vue from 'vue';
import FeatureDestinations from '../vue-components/featured/featured-destinations.vue';

function featureDestinations() {
  new Vue({
    el: '#featured-destinations',
    components: {
      'featured-destinations': FeatureDestinations
    },
    data() {
      return {
        featureddata: window.Cms.featureddestinations
      };
    }
  });
}
module.exports = featureDestinations;
