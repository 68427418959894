import eventBus from '../../vue-functions/event-bus.js';

function typeFilterActions(target) {
  let ddTarget = target;
  if (!this.$data.settings.filtersLocked) {
    this.setFilterVisibility('showTypeFilter');
    this.scrollToFilterSmallScreen(ddTarget);
  }
}
module.exports = typeFilterActions;
