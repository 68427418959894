function matchApiDestinations(valueToMatch, matchType) {
  let destObj = this.$data.settings.filterTripsContent.destination.continents;
  let valueToDisplay = null;

  if (matchType === 'continent') {
    for (let i = 0; i < destObj.length; i++) {
      let currentObj = destObj[i];
      let continentApiName = currentObj.continentApiName;

      if (continentApiName === valueToMatch) {
        valueToDisplay = currentObj.continentName;
      }
    }
  }
  if (matchType === 'country') {
    for (let i = 0; i < destObj.length; i++) {
      let currentContinent = destObj[i];
      let countryList = currentContinent.countries;
      for (let j = 0; j < countryList.length; j++) {
        let currentCountryObj = countryList[j];
        let countryApiName = currentCountryObj.countryApiName;
        if (countryApiName === valueToMatch) {
          valueToDisplay = currentCountryObj.countryName;
        }
      }
    }
  }
  return valueToDisplay;
}
module.exports = matchApiDestinations;
