import Vue from 'vue';
import IncludedView from '../vue-components/tour-cms/included.vue';

function includedView() {
  new Vue({
    el: '#included-section',
    components: {
      'included-view': IncludedView
    },
    data() {
      return {
        includedModel: window.Cms.includedModel,
        dictionarymodel: window.Cms.dictionaryValuesModel,
        raqModel: window.Cms.raqModal
      };
    }
  });
}
module.exports = includedView;
